/* eslint-disable react/no-danger */
import React, { FC, useEffect, useState } from 'react';
import { useStores } from '@stores';
import { observer } from 'mobx-react';
import { graphql } from 'gatsby';
import { TextSection } from '@components/TextSection';
import { ContentPageLayout } from '@components/Layout';
import { Box, Spinner } from '@components/Primitives';
import { FaqAccordion } from '@components/Accordion';
import { ShortcutBox } from '@components/BlueBox';
import { CONTACT_SHORTCUTS } from '../../constants/nav-items';
import { FormattedMessage as M, useIntl } from 'gatsby-plugin-intl';

interface IContactInformationFaqPage {
  data: IMarkdownData;
}

const ContactInformationFaqPage: FC<IContactInformationFaqPage> = observer(({
  data: {
    markdownRemark: { frontmatter: { blocks: texts } },
  },
}) => {
  const {
    faqStore: { getFaqList, faqLists, state }
  }: {
    faqStore: IFaqStore;
  } = useStores();

  const isLoading = state === 'Loading';
  const intl = useIntl();
  const currentLanguage = intl.locale;

  useEffect(() => {
    if (currentLanguage) {
      getFaqList('contact', currentLanguage);
    }
  }, []);

  const getTexts = (id: string) => texts.find((block) => block.id === id);

  const faqList = faqLists.find((list) => list.slug === 'contact');

  return (
    <ContentPageLayout wideContent>
      <TextSection
        title={<M id='fields.label.faqOverview.title'/>} 
        text={<M id='fields.label.faqOverview.text'/>}
      />
      {isLoading && <Spinner color="mainBlue" />}
      {!isLoading && (
        <Box>
          <FaqAccordion id="contact-faq" items={faqList?.faqs?.slice && faqList.faqs.slice(0, 5) as any} />
        </Box>
      )}
      <ShortcutBox items={CONTACT_SHORTCUTS} />
    </ContentPageLayout>
  );
});

export const pageQuery = graphql`
query ContactFaqQuery {
  markdownRemark(frontmatter: {path: {eq: "/contact/faq/"}}) {
    frontmatter {
      path
      blocks {
        title
        text
        id
      }
    }
  }
}`;

export default ContactInformationFaqPage;

